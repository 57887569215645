import * as Icons from '@ant-design/icons';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import FilterForm from 'components/forms/FilterForm';
import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  Table,
  Typography
} from 'antd';
import {
  IClassroom,
  IClassroomEnrollment
} from '../../../interfaces/classrooms';
import { ImportAttendanceModal } from 'components/modal/attendance/ImportAttendanceModal';
import { Link } from 'react-router-dom';
import { TextField, useModal, useTable } from '@refinedev/antd';
import { UpdateAttendanceModal } from 'components/modal/attendance/UpdateAttendanceModal';
import { deleteClassroomEnrollments } from '../../../requests/classroom-enrollment';
import { formatDate, replaceTimeZone } from 'services/date';
import { useInvalidate, useNotification } from '@refinedev/core';

interface IFilter {
  name: string;
  email: string;
  startsAfter: dayjs.Dayjs;
  endsBefore: dayjs.Dayjs;
}

export const ClassroomEnrollments = ({
  classroomId,
  classroom
}: {
  classroomId: string;
  classroom: IClassroom;
}) => {
  const isClassroomClosed = dayjs(classroom.ends_at).isBefore(dayjs());
  const resource = `classrooms/${classroomId}/enrollments`;
  const [toBeUpdated, setToBeUpdated] = useState<IClassroomEnrollment>();
  const [toBeRemoved, setToBeRemoved] = useState<React.Key[]>([]);
  const { show, modalProps, close } = useModal();
  const {
    show: showAttendanceModal,
    modalProps: attendanceModalProps,
    close: closeAttendanceModal
  } = useModal();
  const {
    show: showImportAttendanceModal,
    modalProps: importAttendanceModalProps,
    close: closeImportAttendanceModal
  } = useModal();
  const { open } = useNotification();
  const {
    tableProps,
    tableQueryResult: { refetch },
    searchFormProps
  } = useTable({
    resource,

    onSearch: ({ name, email, startsAfter, endsBefore }: IFilter) => {
      return [
        {
          field: 'filter[search_user_name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_user_email]',
          operator: 'eq',
          value: email
        },
        {
          field: 'filter[course_enrollment_created_after]',
          operator: 'eq',
          value: startsAfter && replaceTimeZone(startsAfter)
        },
        {
          field: 'filter[course_enrollment_created_before]',
          operator: 'eq',
          value: endsBefore && replaceTimeZone(endsBefore)
        }
      ];
    },

    filters: {
      initial: [
        {
          field: 'filter[enrolled]',
          operator: 'eq',
          value: true
        }
      ]
    }
  });

  const onSelectChange = (ids: React.Key[]) => {
    setToBeRemoved(ids);
  };

  const invalidate = useInvalidate();

  const rowSelection = {
    selectedRowKeys: toBeRemoved,
    onChange: onSelectChange
  };

  const handleBatchDelete = async () => {
    await handleDelete();
    close();
  };
  const hasSelected = toBeRemoved.length > 0;

  const handleDelete = async (ids = toBeRemoved) => {
    try {
      await deleteClassroomEnrollments({
        classroomId,
        classroom_enrollment_ids: ids
      });
      invalidate({
        resource: `classrooms/${classroomId}/available_enrollments`,
        invalidates: ['list']
      });
      setToBeRemoved([]);
      refetch();
    } catch {
      open?.({
        type: 'error',
        description: 'Ops! Algo deu errado.',
        message:
          'Não foi possível desmatricular o aluno, por favor tente novamente.'
      });
    }
  };

  const handleUpdateAttendanceRate = useCallback(
    (classromEnrrolment: IClassroomEnrollment) => () => {
      setToBeUpdated(classromEnrrolment);
      showAttendanceModal();
    },
    [showAttendanceModal]
  );

  return (
    <>
      <UpdateAttendanceModal
        modalClose={closeAttendanceModal}
        modalProps={attendanceModalProps}
        refetch={refetch}
        classroomEnrollment={toBeUpdated}
        resourceType="ClassroomEnrollment"
      />
      <ImportAttendanceModal
        modalClose={closeImportAttendanceModal}
        modalProps={importAttendanceModalProps}
        refetch={refetch}
        classroomId={classroomId}
      />
      <ConfirmationModal
        closable={false}
        okText="Confirmar"
        onOk={handleBatchDelete}
        bodyTitle="Desmatricular"
        bodyText="Ao confirmar essa ação, ela não poderá ser desfeita. Deseja continuar?"
        width={500}
        {...modalProps}
      />
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label="Nome do aluno" name="name">
          <Input
            style={{ minWidth: 250 }}
            placeholder="Informe o nome do aluno"
          />
        </Form.Item>
        <Form.Item label="E-mail do aluno" name="email">
          <Input
            style={{ minWidth: 250 }}
            placeholder="Informe o email do aluno"
          />
        </Form.Item>
        <Form.Item label="Início a partir de" name="startsAfter">
          <DatePicker
            className="w-full"
            style={{ minWidth: 250 }}
            placeholder="dia/mês/ano"
            format="DD/MM/YYYY"
          />
        </Form.Item>

        <Form.Item label="Término em" name="endsBefore">
          <DatePicker
            className="w-full"
            style={{ minWidth: 250 }}
            placeholder="dia/mês/ano"
            format="DD/MM/YYYY"
          />
        </Form.Item>
      </FilterForm>
      <Space align="center" className="my-4 justify-between w-full">
        <Typography.Title level={5} style={{ margin: 0, color: 'GrayText' }}>
          Alunos matriculados
        </Typography.Title>
        <Space>
          <Button
            onClick={showImportAttendanceModal}
            icon={<Icons.DownloadOutlined />}
          >
            Importar Frequência
          </Button>
          <Button
            onClick={() => show()}
            disabled={!hasSelected || isClassroomClosed}
          >
            Desmatricular
          </Button>
        </Space>
      </Space>
      <Table
        rowSelection={rowSelection}
        {...tableProps}
        pagination={{
          ...tableProps.pagination,
          totalBoundaryShowSizeChanger: 9
        }}
        rowKey="id"
      >
        <Table.Column<IClassroomEnrollment>
          dataIndex={['course_enrollment', 'user', 'name']}
          key="name"
          title="Nome"
          render={(value, record) => (
            <Link
              to={`/students_teachers/users/show/${record.course_enrollment.user.id}`}
            >
              {value}
            </Link>
          )}
        />
        <Table.Column
          dataIndex={['course_enrollment', 'user', 'email']}
          key="email"
          title="E-mail"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={['course_enrollment', 'registration']}
          key="registration"
          title="Matrícula"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={['course_enrollment', 'course', 'name']}
          key="course"
          title="Curso"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          className="whitespace-nowrap"
          dataIndex={['course_enrollment', 'created_at']}
          key="course"
          title="Data início"
          render={value => (
            <TextField value={formatDate(value, 'DD/MM/YYYY')} />
          )}
        />
        <Table.Column
          dataIndex="attendance_rate"
          key="attendance_rate"
          title="Frequência"
          render={value => <TextField value={`${value} %`} />}
        />
        <Table.Column<IClassroomEnrollment>
          dataIndex="actions"
          key="actions"
          title="Ações"
          render={(_, record) => {
            return (
              <Space direction="horizontal">
                <Button
                  size="small"
                  onClick={handleUpdateAttendanceRate(record)}
                >
                  Editar Frequência
                </Button>
                <Button
                  size="small"
                  disabled={isClassroomClosed || record.score !== null}
                  onClick={() => {
                    handleDelete([record.id]);
                  }}
                >
                  Desmatricular
                </Button>
              </Space>
            );
          }}
        />
      </Table>
    </>
  );
};
