import { CrudFilters, HttpError } from '@refinedev/core';
import { ICourseCertificate } from 'interfaces/course_certificate';
import { IssuedCertificatesFilter } from './IssuedCertificatesFilter';
import { List, ShowButton, TextField, useTable } from '@refinedev/antd';
import { Table } from 'antd';
import { formatDate } from 'services/date';

export interface IFilter {
  by_registration_number: string;
  by_user_name: string;
  by_enrollment_registration: string;
  by_cpf: string;
  by_course_id: string;
  issued_after: string;
  issued_before: string;
  registered_after: string;
  registered_before: string;
}

const IssuedCertificates = () => {
  const { tableProps, searchFormProps } = useTable<
    ICourseCertificate,
    HttpError,
    IFilter
  >({
    onSearch: params => {
      const filters: CrudFilters = [];
      const {
        by_registration_number,
        by_user_name,
        by_enrollment_registration,
        by_cpf,
        by_course_id,
        issued_after,
        issued_before,
        registered_after,
        registered_before
      } = params;

      filters.push(
        {
          field: 'filter[by_registration_number]',
          operator: 'eq',
          value: by_registration_number
        },
        {
          field: 'filter[by_user_name]',
          operator: 'eq',
          value: by_user_name
        },
        {
          field: 'filter[by_enrollment_registration]',
          operator: 'eq',
          value: by_enrollment_registration
        },
        {
          field: 'filter[by_cpf]',
          operator: 'eq',
          value: by_cpf
        },
        {
          field: 'filter[by_course_id]',
          operator: 'eq',
          value: by_course_id
        },
        {
          field: 'filter[issued_after]',
          operator: 'eq',
          value: issued_after
        },
        {
          field: 'filter[issued_before]',
          operator: 'eq',
          value: issued_before
        },
        {
          field: 'filter[registered_after]',
          operator: 'eq',
          value: registered_after
        },
        {
          field: 'filter[registered_before]',
          operator: 'eq',
          value: registered_before
        }
      );

      return filters;
    }
  });
  return (
    <List breadcrumb={null} title={''} canCreate={false}>
      <IssuedCertificatesFilter searchFormProps={searchFormProps} />
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="registration_number"
          key="registration_number"
          title="Nº de registro"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="user"
          key="name"
          title="Nome do aluno"
          render={({ name }) => <TextField value={name} />}
        />
        <Table.Column
          dataIndex="enrollment_registration"
          key="enrollment_registration"
          title="Nº de matrícula"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="user"
          key="cpf"
          title="CPF"
          render={({ personal_info }) => (
            <TextField value={personal_info.document_info} />
          )}
        />
        <Table.Column
          dataIndex="course_name"
          key="course_name"
          title="Curso"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="registered_at"
          key="registered_at"
          title="Data de registro"
          render={value => (
            <TextField value={formatDate(value, 'DD/MM/YYYY')} />
          )}
        />
        <Table.Column
          dataIndex="issued_at"
          key="issued_at"
          title="Data de expedição"
          render={value => (
            <TextField value={formatDate(value, 'DD/MM/YYYY')} />
          )}
        />
        <Table.Column<ICourseCertificate>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => {
            return (
              <ShowButton hideText size="small" recordItemId={record.id} />
            );
          }}
        />
      </Table>
    </List>
  );
};

export default IssuedCertificates;
