import * as Icons from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import {
  AccessControlContext,
  CanAccess,
  CanParams,
  IResourceItem,
  ITreeMenu,
  useCan,
  useKeys,
  useLogout,
  useMenu,
  useNavigation
} from '@refinedev/core';
import { Grid, Layout as LayoutAntd, Menu } from 'antd';
import { Title } from '../title';
import { antLayoutSider, antLayoutSiderMobile, antMenuRoot } from './styles';
import { helpIcon } from '../../../assets/icons/help';
import { logoutIcon } from '../../../assets/icons/logout';
import { styled } from '@qcx/ui';
import { useQueries } from '@tanstack/react-query';

const { UnorderedListOutlined } = Icons;
const { SubMenu } = Menu;

export const Sider: React.FC = () => {
  // TODO - Extrair
  const hiddenItems = new Set(['graduate_modules']);

  const [collapsed, setCollapsed] = useState(false);
  const { mutate: logout } = useLogout();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  return (
    <StyledSider
      width={250}
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      <Title collapsed={collapsed && !isMobile} />

      <StyledMenu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        style={antMenuRoot}
        onClick={({ key }) => {
          if (key === 'logout') {
            logout();
            return;
          }

          if (key === 'help') {
            window.open('https://grupoq.zendesk.com/hc/pt-br');
            return;
          }

          if (!breakpoint.lg) {
            setCollapsed(true);
          }
        }}
      >
        {menuItems.map((item: ITreeMenu) => {
          return (
            <TreeViewRendererItem
              key={item.name}
              item={item}
              collapsed={collapsed}
              selectedKey={selectedKey}
              hiddenItems={hiddenItems}
            />
          );
        })}
        <Menu.Item key="help" icon={helpIcon}>
          Central de ajuda
        </Menu.Item>
        <Menu.Item key="logout" icon={logoutIcon}>
          Sair
        </Menu.Item>
      </StyledMenu>
    </StyledSider>
  );
};

export const sanitizeResource = (
  resource?: Partial<IResourceItem> &
    Required<Pick<IResourceItem, 'name'>> & { children?: unknown }
):
  | (Partial<IResourceItem> & Required<Pick<IResourceItem, 'name'>>)
  | undefined => {
  if (!resource) {
    return undefined;
  }

  const {
    icon,
    list,
    edit,
    create,
    show,
    clone,
    children,
    meta,
    options,
    ...restResource
  } = resource;

  const { icon: _metaIcon, ...restMeta } = meta ?? {};
  const { icon: _optionsIcon, ...restOptions } = options ?? {};

  return {
    ...restResource,
    ...(meta ? { meta: restMeta } : {}),
    ...(options ? { options: restOptions } : {})
  };
};

export const useCanMultiple = (permissions: CanParams[]) => {
  const { can, options: globalOptions } = useContext(AccessControlContext);
  const { keys, preferLegacyKeys } = useKeys();

  const { queryOptions: globalQueryOptions } = globalOptions || {};

  const queries = permissions.map(({ action, resource, params }) => {
    const { resource: _resource, ...paramsRest } = params ?? {};
    const sanitizedResource = sanitizeResource(_resource);

    return {
      queryKey: keys()
        .access()
        .resource(resource)
        .action(action)
        .params({
          params: { ...paramsRest, resource: sanitizedResource },
          enabled: globalQueryOptions?.enabled
        })
        .get(preferLegacyKeys),
      queryFn: () =>
        can?.({
          action,
          resource,
          params: { ...paramsRest, resource: sanitizedResource }
        }) ?? Promise.resolve({ can: true }),
      enabled: typeof can !== 'undefined',
      ...globalQueryOptions
    };
  });

  return useQueries({ queries });
};

export const TreeViewRendererItem = ({
  item,
  collapsed = false,
  selectedKey,
  hiddenItems = new Set(),
  ...props
}: {
  item: ITreeMenu;
  collapsed?: boolean;
  selectedKey: string;
  hiddenItems?: Set<string>;
}) => {
  const { icon, label, route, name, children, parentName } = item;

  const result = useCanMultiple(
    children.map(child => ({
      resource: child.name.toLowerCase(),
      action: 'list'
    }))
  );

  const { push } = useNavigation();

  const renderMenuItem = (menuItem: ITreeMenu) => {
    const { icon, label, route, name, parentName } = menuItem;
    const isSelected = route === selectedKey;
    const isRoute = !(
      parentName !== undefined && menuItem.children.length === 0
    );

    return (
      <CanAccess key={route} resource={name.toLowerCase()} action="list">
        <Menu.Item
          {...props}
          key={name}
          onClick={() => {
            push(route ?? '');
          }}
          style={{
            fontWeight: isSelected ? 'bold' : 'normal',
            display: hiddenItems.has(name) ? 'none' : 'flex'
          }}
          icon={icon ?? (isRoute && <UnorderedListOutlined />)}
        >
          {label}
          {!collapsed && isSelected && <div className="ant-menu-tree-arrow" />}
        </Menu.Item>
      </CanAccess>
    );
  };

  const noneChildrenVisible = result.every(query => query.data?.can === false);

  return (
    <>
      {children.length > 0 && !noneChildrenVisible ? (
        <SubMenu
          {...props}
          key={name}
          icon={icon ?? <UnorderedListOutlined />}
          title={label}
        >
          {children.map((childItem: ITreeMenu) => renderMenuItem(childItem))}
        </SubMenu>
      ) : (
        renderMenuItem(item)
      )}
    </>
  );
};

export const StyledSider = styled(LayoutAntd.Sider, {
  '.ant-layout-sider-zero-width-trigger': {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    height: 'auto',
    top: 0,
    right: 0,
    borderRadius: '0 0 0 6px',
    padding: '10px',
    fontSize: '23px'
  },
  '.ant-layout-has-sider &::-webkit-scrollbar': {
    width: '5px'
  },
  '.ant-layout-has-sider &::-webkit-scrollbar-track': {
    borderRadius: 0,
    backgroundColor: 'rgba(255,255,255,.3)'
  },
  '.ant-layout-has-sider &::-webkit-scrollbar-thumb': {
    background: 'rgba(0,0,0,0.7)',
    borderRadius: '10px'
  }
});

export const StyledMenu = styled(Menu, {
  '.ant-menu-tree-arrow': {
    position: 'absolute',
    top: '50%',
    right: '16px',
    width: '10px',
    color: '#fff',
    transform: 'translateY(-50%)',
    transition: 'transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)'
  },
  '.ant-menu-submenu-popup > .ant-menu': {
    backgroundColor: '#2a132e'
  },
  'ul.ant-menu.ant-menu-sub.ant-menu-inline': {
    position: 'relative',
    '&:before': {
      content: '',
      position: 'absolute',
      border: '1px solid rgba(255, 255, 255, .3)',
      height: '100%',
      zIndex: 1,
      left: '30px'
    }
  },
  '.ant-menu-tree-arrow::before,.ant-menu-tree-arrow::after': {
    position: 'absolute',
    width: '6px',
    height: '1.5px',
    backgroundColor: 'currentcolor',
    borderRadius: '2px',
    transition:
      'background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    content: ''
  },
  '.ant-menu-tree-arrow::before': {
    transform: 'rotate(45deg) translateY(-2.5px)'
  },
  '.ant-menu-tree-arrow::after': {
    transform: 'rotate(-45deg) translateY(2.5px)'
  }
});
